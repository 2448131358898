"use strict";

const $ = require("./main.js");

let didScroll;
let lastScrollTop = 0;
let delta = 5;
let navbarHeight = $('.js-site-header').outerHeight();

let prevRatio = 0.0;
const numSteps = 20.0;

$(document).ready(function ($) {

  const th = function () {
    let thresholds = [];
    let numSteps = 20;

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    thresholds.push(0);
    return thresholds;
  };

  let observer;
  let options = {
    root: null,
    rootMargin: "0px",
    threshold: th(),
  };
  observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        $('.community-cta-bar').addClass('inactive');
        $('body').removeClass('community-cta-bar-active');
      } else {
        $('.community-cta-bar').removeClass('inactive');
        $('body').addClass('community-cta-bar-active');
      }
    });
  }, options);
  observer.observe(document.querySelector("#footer"));


  localStorage.setItem('lastCommunity', JSON.stringify({
    title: $('#lastCommunityTitle').val(),
    img: $('#lastCommunityImg').val(),
    link: $('#lastCommunityLink').val(),
  }));


  $(document).on('click', '.js-village-hero-gallery-community', function () {
    var modalId = $(this).data('src');
    $.fancybox.open({
      src: modalId,
      type: 'inline',
      touch: false,
      afterShow: function () {
        var flkty = new Flickity(modalId + ' .slides--full-width', {
          cellAlign: 'center',
          contain: false,
          wrapAround: false,
          bgLazyLoad: 1,
          adaptiveHeight: true
        });
      }
    });

    return false;
  });


  //----------------------------------------------------------------------------
  // forms
  //----------------------------------------------------------------------------


  // Attempt to load a d365 form
  // this assumes that the d365 script has already been inected in to the page
  // and that there is a div container with the required popreties, but renamed to data-defer-<prop>
  function loadD365(container, selector='[data-defer-form-id]') {
    const configElement = container.querySelector(selector);
    if (!configElement) {
      return;
    }

    const {
      deferFormId: formId,
      deferFormApiUrl: formApiUrl,
      deferCachedFormUrl: cachedFormUrl,
    } = configElement.dataset;

    // sneak in a wrapper
    // that has an id we can grab... to yank later
    const f = document.createElement('div');
    f.id=`form_${formId}`;
    configElement.parentNode.appendChild(f);

    window.triggerD365(
      f,
      {
        formId,
        formApiUrl,
        cachedFormUrl,
      }
    );
  }

  // find a form that we have added, and remove it so we can make sure
  // that any new forms are able to be added.
  // this is assumed to be when a modal closes
  function yankOnClose(container, selector='[data-defer-form-id]') {
    const configElement = container.querySelector(selector);
    if (!configElement) {
      return;
    }

    const {
      deferFormId: formId,
    } = configElement.dataset;

    const f = document.querySelector(`#form_${formId}`);
    if (f) {
      f.parentNode.removeChild(f);
    }

  }

  $('[data-fancybox="book-a-visit"],[data-fancybox="book-a-visit-cta"]').fancybox({
    // Options will go here
    touch: false,
    src: '#book-a-visit',
    afterLoad: function (instance, current) {
      loadD365(this.$content[0]);
    },
    afterClose: function (instance, current) {
      yankOnClose(this.$content[0]);
    }
  });

  $('[data-fancybox="info-pack"],[data-fancybox="info-pack-cta"]').fancybox({
    // Options will go here
    touch: false,
    src: '#info-pack',
    afterLoad: function (instance, current) {
      loadD365(this.$content[0]);
    },
    afterClose: function (instance, current) {
      yankOnClose(this.$content[0])
    }
  });

});
